<template>
  <div class="my" v-if="userInfo">
    <BgTop></BgTop>
    <div class="my-main">
      <!-- 头像模块 -->
      <div class="head-box">
        <div class="herd-left">
          <van-uploader :after-read="afterRead">
            <div class="head">
              <img v-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" />
              <img v-else src="@/assets/image/head.png" />
            </div>
          </van-uploader>

          <div class="name-box">
            <div class="name">{{ userInfo.name }}</div>

            <div class="grade-box">
              <div class="grade">
                <img
                  class="grade-name"
                  :src="require(`@/assets/image/v${IncreaseNumber}.png`)"
                />
                <span>等级</span>
              </div>
              <img
                class="icon-grade"
                :src="require(`@/assets/image/credit${IncreaseNumber}.png`)"
              />
            </div>
          </div>
        </div>
        <van-uploader :after-read="afterRead">
          <img class="arrow-right" src="@/assets/image/arrow-right.png" />
        </van-uploader>
      </div>
      <!-- 余额 -->
      <div class="balance-box">
        <div class="balance-item" @click="goUrl('/mobile/myAccount')">
          <div class="number">{{ userInfo.balance }}<span>金币</span></div>
          <div class="tips">账户余额</div>
        </div>
        <div class="line"></div>
        <div class="balance-item" @click="goUrl('/mobile/myCredit')">
          <div class="number">{{ userInfo.credit }}</div>
          <div class="tips">信用值</div>
        </div>
      </div>
      <!-- 我的服务 -->
      <div class="service-box">
        <div class="title">我的服务</div>
        <div class="card-box">
          <div
            :class="`card-item card-item${index}`"
            @click="goUrl(item.path)"
            v-for="(item, index) in serviceList"
            :key="item.name"
          >
            <img class="card-img" :src="item.url" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 我的邀请 -->
      <div class="invite-box">
        <div class="invite-title">
          <span>我的邀请</span>
          <div class="invite-right" @click="goUrl('/mobile/myInvite')">
            查看详情
            <img src="@/assets/image/grey-right.png" />
          </div>
        </div>
        <div class="invite-friend">
          <div class="title">邀请好友</div>
          <div class="invite-friend-bottom">
            邀请一位好友可获得奖励
            <span class="btn" @click="goUrl('/mobile/myInvite')">立即邀请</span>
          </div>
        </div>
        <div class="invite-number">
          <div class="invite-number-card">
            <div class="invite-number-card-left">
              <span class="name">{{ friendCount }}</span>
              <span>已邀请</span>
            </div>
            <img src="@/assets/image/friend1.png" />
          </div>
          <div class="invite-number-card">
            <div class="invite-number-card-left">
              <span class="name">{{ moneyCount }}</span>
              <span>专区现金</span>
            </div>
            <img src="@/assets/image/friend2.png" />
          </div>
        </div>
      </div>
      <!-- 其他服务 -->
      <div class="other-box">
        <div class="title">其他服务</div>
        <div class="card-box">
          <div
            class="card-item"
            @click="goUrl(item.path)"
            v-for="item in otherList"
            :key="item.name"
          >
            <img class="card-img" :src="item.url" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <Tabbar :tabbarType="2"></Tabbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      serviceList: [
        {
          url: require("@/assets/image/service1.png"),
          name: "答题历史",
          path: "/mobile/answeringHistory"
        },
        {
          url: require("@/assets/image/service2.png"),
          name: "卡券专区",
          path: "/mobile/couponZone"
        },
        {
          url: require("@/assets/image/service3.png"),
          name: "兑换记录",
          path: "/mobile/exchangeList"
        },
        {
          url: require("@/assets/image/service4.png"),
          name: "每日签到",
          path: "/mobile/signIn"
        }
      ],
      otherList: [
        {
          url: require("@/assets/image/other1.png"),
          name: "个人资料"
        },
        {
          url: require("@/assets/image/other2.png"),
          name: "账户设置",
          path: "/mobile/accountSettings"
        },
        {
          url: require("@/assets/image/other3.png"),
          name: "信用明细",
          path: "/mobile/myCreditDetails"
        },
        {
          url: require("@/assets/image/other4.png"),
          name: "常见问题"
        },
        {
          url: require("@/assets/image/other5.png"),
          name: "用户协议"
        },
        {
          url: require("@/assets/image/other6.png"),
          name: "隐私条款"
        },
        {
          url: require("@/assets/image/other7.png"),
          name: "修改密码",
          path: "/mobile/changePassword"
        },
        {
          url: require("@/assets/image/other8.png"),
          name: "关于我们"
        }
      ],
      userInfo: null,
      IncreaseNumber: 1,
      credit: 0,
      show: true,
      friendCount: 0,
      moneyCount: 0
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
    afterRead(file) {
      console.log("file", file.content);
      axios
        .post(`${process.env.VUE_APP_SERVER}/api/v1/UploadAvatar`, {
          source: file.content
        })
        .then(async res => {
          console.log(res);
          console.log("this", this);
          if (res.status === 200) {
            let userid = localStorage.getItem("userid");
            const res2 = await this.$http.get(
              `/api/v1/Accounts/${userid}/Avatar`,
              {
                params: {
                  id: userid,
                  avatarUrl: res.data.data.avatarUrl
                }
              }
            );
            if (res2.status === 200) {
              this.$toast("修改成功");
              const res3 = await this.$http.get(`/api/v1/Accounts/${userid}`, {
                params: {
                  id: userid
                }
              });
              if (res3.status === 200) {
                localStorage.setItem("userInfo", JSON.stringify(res3.data));
                this.userInfo = res3.data;
              }
            }
          }
        });
    },
    async getRecommend() {
      let userid = localStorage.getItem("userid");
      const res = await this.$http.get(`/api/v1/Accounts/${userid}/Recommend`, {
        params: {
          id: userid
        }
      });

      if (res.status === 200) {
        this.friendCount = res.data.friendCount;
        this.moneyCount = res.data.moneyCount;
      }
    }
  },
  async created() {
    if (!localStorage.getItem("userInfo")) {
      if (localStorage.getItem("userid")) {
        let userid = localStorage.getItem("userid");
        const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
          params: {
            id: userid
          }
        });
        if (res.status === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      } else {
        this.$router.push("/mobile");
      }
    }

    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.credit = this.userInfo.credit;

    if (this.credit <= 60) {
      this.IncreaseNumber = 1;
    } else if (61 <= this.credit && this.credit <= 100) {
      this.IncreaseNumber = 2;
    } else if (101 <= this.credit && this.credit <= 200) {
      this.IncreaseNumber = 3;
    } else if (201 <= this.credit && this.credit <= 500) {
      this.IncreaseNumber = 4;
    } else if (501 <= this.credit && this.credit <= 800) {
      this.IncreaseNumber = 5;
    } else {
      this.IncreaseNumber = 6;
    }

    await this.getRecommend();
  }
};
</script>

<style lang="scss" scoped>
.my {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #f5f5f8;

  .my-main {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 10px 15px 60px 15px;

    // 头像模块
    .head-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 17px;
      .herd-left {
        display: flex;
        align-items: center;
        .head {
          width: 56px;
          height: 56px;
          border-radius: 28px;
          overflow: hidden;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name-box {
          display: flex;
          flex-direction: column;
          .name {
            color: #232323;
            font-size: 18px;
            font-weight: bolder;
          }
          .grade-box {
            display: flex;
            margin-top: 12px;
            .grade {
              width: 55px;
              height: 22px;
              background: rgba(255, 162, 0, 0.1);
              border-radius: 11px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffa200;
              font-size: 12px;
              .grade-name {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            .icon-grade {
              width: 23px;
              margin: 0 8px;
            }
          }
        }
      }
      .arrow-right {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
      }
    }

    //余额
    .balance-box {
      width: 100%;
      height: 77px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      margin-top: 24px;
      .line {
        width: 1px;
        height: 41px;
        background: #ededed;
      }
      .balance-item {
        width: calc((100% - 1px) / 2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .number {
          color: #232323;
          font-size: 28px;
          font-weight: bolder;
          // margin-top: 14px;
          span {
            font-weight: normal;
            font-size: 12px;
            margin-left: 4px;
          }
        }
        .tips {
          color: #999999;
          font-size: 12px;
          // margin-top: 10px;
        }
      }
    }

    // 我的服务
    .service-box {
      padding: 17px 14px;
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      margin-top: 10px;
      .title {
        color: #232323;
        font-size: 16px;
        font-weight: bolder;
      }
      .card-box {
        display: flex;
        justify-content: space-between;
        .card-item {
          display: flex;
          flex-direction: column;
          color: #232323;
          flex-wrap: wrap;
          font-size: 12px;
          margin-top: 15px;
          .card-img {
            width: 48px;
            height: 48px;
            box-shadow: 0px 3px 12px 0px rgba(255, 151, 173, 0.349);
            margin-bottom: 11px;
            border-radius: 24px;
          }
        }
        .card-item0 {
          .card-img {
            background: linear-gradient(318deg, #ff6a88 0%, #ff8dca 100%);
            box-shadow: 0px 3px 12px 0px rgba(255, 151, 173, 0.349);
          }
        }
        .card-item1 {
          .card-img {
            background: linear-gradient(324deg, #6c93ff 0%, #40c3f4 100%);
            box-shadow: 0px 3px 12px 0px rgba(118, 175, 254, 0.4);
          }
        }
        .card-item2 {
          .card-img {
            background: linear-gradient(324deg, #11d15a 0%, #3de58d 100%);
            box-shadow: 0px 3px 12px 0px rgba(100, 220, 175, 0.349);
          }
        }
        .card-item3 {
          .card-img {
            width: 48px;
            height: 48px;
            background: linear-gradient(150deg, #ffd21c 0%, #ff8112 100%);
            box-shadow: 0px 3px 12px 0px rgba(255, 206, 39, 0.349);
          }
        }
      }
    }

    // 我的邀请
    .invite-box {
      padding: 17px 14px;
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      margin-top: 10px;
      .invite-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #232323;
          font-size: 16px;
          font-weight: bolder;
        }
        .invite-right {
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 14px;
          img {
            width: 14px;
            height: 14px;
            margin-left: 7px;
          }
        }
      }
      .invite-friend {
        width: 100%;
        background: rgba(255, 162, 0, 0.1);
        border-radius: 8px 8px 8px 8px;
        margin-top: 15px;
        box-sizing: border-box;
        padding: 20px 14px 15px 20px;
        .title {
          color: #cb630a;
          font-size: 16px;
          font-weight: bolder;
        }
        .invite-friend-bottom {
          display: flex;
          flex-wrap: wrap;
          color: #c78145;
          font-size: 14px;
          margin-top: 5px;
          justify-content: space-between;
          align-items: flex-end;
          .btn {
            width: 92px;
            height: 30px;
            background: linear-gradient(#ffa150 0%, #ffbb02 100%);
            border-radius: 15px;
            color: #fff;
            text-align: center;
            line-height: 30px;
          }
        }
      }
      .invite-number {
        display: flex;
        justify-content: space-between;
        margin-top: 11px;
        .invite-number-card {
          width: calc((100% - 10px) / 2);
          background: rgba(255, 162, 0, 0.1);
          height: 84px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .invite-number-card-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            height: 100%;
            font-size: 12px;
            color: #c78145;
            padding-left: 18px;
            // flex-shrink: 0;
            box-sizing: border-box;
            .name {
              color: #cb630a;
              font-size: 20px;
              font-weight: bolder;
              margin-bottom: 5px;
            }
          }
          img {
            width: 67px;
            height: 58px;
            flex-shrink: 0;
          }
        }
      }
    }
    // 其他服务
    .other-box {
      padding: 17px 14px;
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      margin-top: 10px;
      .title {
        color: #232323;
        font-size: 16px;
        font-weight: bolder;
      }
      .card-box {
        display: flex;
        flex-wrap: wrap;
        .card-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          color: #232323;
          flex-wrap: wrap;
          font-size: 14px;
          margin-top: 25px;
          width: 25%;
          .card-img {
            width: 26px;
            height: 26px;
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
/* .van-uploader{
  width: 100%;
}
.head-box >>> .van-uploader__input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
</style>
